import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// shortcut rfc
export default function Navbar(props) {
  return (
    // literal
    <nav className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}> 
  <div className="container-fluid " >
    <Link className="navbar-brand" to="/">{props.title}</Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">{props.about}</Link>
        </li>
      </ul>
    <div className={`form-check form-switch text-${props.mode==='light'?'dark':'light'}`}>
  <input className="form-check-input" onClick={props.toggleMode} type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
  <label className="form-check-label mx-3" htmlFor="flexSwitchCheckDefault">Enable Dark Mode</label>
</div>
      <form className="d-flex">
        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
        <button className="btn btn-outline-success" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav>
  )
}

// Navbar.propTypes = {
//     title: PropTypes.string,    //pts
//     about: PropTypes.string,    //pts
// }
Navbar.propTypes = {
    title: PropTypes.string.isRequired,    //ptsr
    about: PropTypes.string,    //pts
}

Navbar.defaultProps = {
    title: 'Set title',
    about: 'About text'
}